"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectors = void 0;
exports.selectors = {
    'ncode.syosetu.com': {
        metaData: {
            name: "小説家になろう",
            version: 1,
            metaVersion: 1,
            recommend: true,
            url: {
                index: "https://ncode\.syosetu\.com/[^/]+/$",
                episode: "https://ncode\.syosetu\.com/[^/]+/[0-9]*"
            }
        },
        episode: {
            title: '//div[contains(@class, "contents1")]/a[1]',
            author: 'RAWHTML!!SAND??name="twitter:creator" content="??">',
            chapterTitle: '//p[contains(@class, "chapter_title")]',
            subTitle: '//p[contains(@class, "novel_subtitle")]',
            text: {
                prev: {
                    text: '//div[@id="novel_p"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
                    image: {
                        check: '<img src=\"\/\/[0-9]*.mitemin.net\/userpageimage\/viewimagebig\/icode\/i[0-9]*\/">',
                        url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                        alt: ''
                    }
                },
                main: {
                    text: '//div[@id="novel_honbun"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
                    image: {
                        check: '<img src=\"\/\/[0-9]*.mitemin.net\/userpageimage\/viewimagebig\/icode\/i[0-9]*\/">',
                        url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                        alt: ''
                    }
                },
                after: {
                    text: '//div[@id="novel_a"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
                    image: {
                        check: '<img src=\"\/\/[0-9]*.mitemin.net\/userpageimage\/viewimagebig\/icode\/i[0-9]*\/">',
                        url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                        alt: ''
                    }
                }
            },
            link: {
                author: 'VAL!//div[@id="novel_footer"]/ul/li[1]/a[contains(text(), "作者マイページ")]/@href',
                next: 'VAL!//div[@id="novel_color"]/div[1]/a[contains(text(), "次へ")]/@href',
                prev: 'VAL!//div[@id="novel_color"]/div[1]/a[contains(text(), "前へ")]/@href',
                index: 'VAL!//div[contains(@class, "contents1")]/a[1]/@href'
            }
        },
        index: {
            title: '//p[contains(@class, "novel_title")]',
            author: '//div[contains(@class, "novel_writername")]/a!!REPLACE??作者：??',
            abstract: '//div[@id="novel_ex"]!!REPLACE??\n??!!REPLACE??<br>??\n!!REPLACE??<span>…</span><span class=\"hidden\">??!!REPLACE??</span><a class=\"more\">&gt;&gt;続きを読む</a>??',
            link: {
                author: 'VAL!//div[@id="novel_footer"]/ul/li[1]/a[contains(text(), "作者マイページ")]/@href',
            },
            item: {
                container: '//div[contains(@class, "index_box")]/*',
                chapter: {
                    check: 'contains(./@class, "chapter_title")',
                    title: '.',
                },
                episode: {
                    check: 'contains(./@class, "novel_sublist2")',
                    title: './dd[contains(@class, "subtitle")]/a',
                    link: 'VAL!./dd[contains(@class, "subtitle")]/a/@href',
                    date: './dt[contains(@class, "long_update")]',
                    dateFormat: 'YYYY/MM/DD HH:mm'
                }
            }
        }
    },
    'novel18.syosetu.com': {
        metaData: {
            name: "ノクターン・ミッドナイト・ムーンライト",
            version: 1,
            metaVersion: 1,
            recommend: false,
            url: {
                index: "https://novel18\.syosetu\.com/[^/]+/$",
                episode: "https://novel18\.syosetu\.com/[^/]+/[0-9]*"
            }
        },
        episode: {
            title: '//div[contains(@class, "contents1")]/a[1]',
            author: 'RAWHTML!!SAND??name="twitter:creator" content="??">',
            chapterTitle: '//p[contains(@class, "chapter_title")]',
            subTitle: '//p[contains(@class, "novel_subtitle")]',
            text: {
                prev: {
                    text: '//div[@id="novel_p"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
                    image: {
                        check: '<img src=\"\/\/[0-9]*.mitemin.net\/userpageimage\/viewimagebig\/icode\/i[0-9]*\/">',
                        url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                        alt: ''
                    }
                },
                main: {
                    text: '//div[@id="novel_honbun"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
                    image: {
                        check: '<img src=\"\/\/[0-9]*.mitemin.net\/userpageimage\/viewimagebig\/icode\/i[0-9]*\/">',
                        url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                        alt: ''
                    }
                },
                after: {
                    text: '//div[@id="novel_a"]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
                    image: {
                        check: '<img src=\"\/\/[0-9]*.mitemin.net\/userpageimage\/viewimagebig\/icode\/i[0-9]*\/">',
                        url: 'REPLACE??^<img src="??!!REPLACE??">$??!!REPLACE??^??https:',
                        alt: ''
                    }
                }
            },
            link: {
                author: 'VAL!//div[@id="novel_footer"]/ul/li[1]/a[contains(text(), "作者マイページ")]/@href',
                next: 'VAL!//div[@id="novel_color"]/div[1]/a[contains(text(), "次へ")]/@href',
                prev: 'VAL!//div[@id="novel_color"]/div[1]/a[contains(text(), "前へ")]/@href',
                index: 'VAL!//div[contains(@class, "contents1")]/a[1]/@href'
            }
        },
        index: {
            title: '//p[contains(@class, "novel_title")]',
            author: '//div[contains(@class, "novel_writername")]/a!!REPLACE??作者：??',
            abstract: '//div[@id="novel_ex"]!!REPLACE??\n??!!REPLACE??<br>??\n',
            link: {
                author: 'VAL!//div[@id="novel_footer"]/ul/li[1]/a[contains(text(), "作者マイページ")]/@href',
            },
            item: {
                container: '//div[contains(@class, "index_box")]/*',
                chapter: {
                    check: 'contains(./@class, "chapter_title")',
                    title: '.',
                },
                episode: {
                    check: 'contains(./@class, "novel_sublist2")',
                    title: './dd[contains(@class, "subtitle")]/a',
                    link: 'VAL!./dd[contains(@class, "subtitle")]/a/@href',
                    date: './dt[contains(@class, "long_update")]',
                    dateFormat: 'YYYY/MM/DD HH:mm'
                }
            }
        }
    },
    'syosetu.org': {
        metaData: {
            name: "ハーメルン",
            version: 1,
            metaVersion: 1,
            recommend: true,
            url: {
                index: "https://syosetu\.org/novel/[0-9]*/$",
                episode: "https://syosetu\.org/novel/[0-9]*/([0-9]*\.html)?$"
            }
        },
        episode: {
            title: '//div[contains(@class, "ss")]/p/span/a',
            author: '//div[contains(@class, "ss")]/p/a',
            chapterTitle: '',
            subTitle: '//div[contains(@class, "ss")]/span!!REPLACE??.*<br>??!!REPLACE??^ ??',
            text: {
                prev: {
                    text: '//div[@id="maegaki"]!!REPLACE??<br><br>$??!!REPLACE??<br>??\n',
                    image: {
                        check: '<a href=\"http:\/\/syosetu.org\/img\/.+?\">',
                        url: 'REPLACE??^<a href="??!!REPLACE??">$??!!REPLACE??http://syosetu.org??https://img.syosetu.org',
                        alt: ''
                    }
                },
                main: {
                    text: '//div[@id="honbun"]!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
                    image: {
                        check: '<a href=\"http:\/\/syosetu.org\/img\/.+?\">',
                        url: 'REPLACE??^<a href="??!!REPLACE??">$??!!REPLACE??http://syosetu.org??https://img.syosetu.org',
                        alt: ''
                    }
                },
                after: {
                    text: '//div[@id="atogaki"]!!REPLACE??^<br><br>??!!REPLACE??<br>??\n',
                    image: {
                        check: '<a href=\"http:\/\/syosetu.org\/img\/.+?\">',
                        url: 'REPLACE??^<a href="??!!REPLACE??">$??!!REPLACE??http://syosetu.org??https://img.syosetu.org',
                        alt: ''
                    }
                }
            },
            link: {
                author: 'VAL!//div[contains(@class, "ss")]/p/a/@href',
                next: 'VAL!//li[contains(@class, "novelnb")]/a[contains(text(), "次の話")]/@href',
                prev: 'VAL!//li[contains(@class, "novelnb")]/a[contains(text(), "前の話")]/@href',
                index: 'VAL!//li[contains(@class, "novelmokuzi")]/a/@href',
            }
        },
        index: {
            title: '//div[contains(@class, "ss")]/span',
            author: '//div[contains(@class, "ss")]/div/span/a',
            abstract: '//div[contains(@class, "ss")][2]!!REPLACE??<br>??\n',
            link: {
                author: 'VAL!//div[contains(@class, "ss")]/div/span/a/@href',
            },
            item: {
                container: '//div[contains(@class, "ss")]/table/tbody/*',
                chapter: {
                    check: 'count(./*) = 1',
                    title: './td/strong',
                },
                episode: {
                    check: 'count(./*) = 2',
                    title: './td[1]/a',
                    link: 'VAL!./td[1]/a/@href',
                    date: './td[2]/nobr',
                    dateFormat: 'YYYY年MM月DD日 HH:mm'
                }
            }
        }
    },
    'kakuyomu.jp': {
        metaData: {
            name: "カクヨム",
            version: 1,
            metaVersion: 1,
            recommend: true,
            url: {
                index: "https://kakuyomu\.jp/works/[0-9]*$",
                episode: "https://kakuyomu\.jp/works/[0-9]*/episodes/[0-9]*$"
            }
        },
        episode: {
            title: '//span[@itemprop="name"]',
            author: '//title!!SAND??（??）',
            chapterTitle: '//p[contains(@class, "chapterTitle")]/span',
            subTitle: '//p[contains(@class, "widget-episodeTitle")]',
            text: {
                prev: {
                    text: '',
                    image: {
                        check: '',
                        url: '',
                        alt: ''
                    }
                },
                main: {
                    text: '//div[contains(@class, "widget-episodeBody")]!!REPLACE??\n??!!REPLACE??<p .+?>??!!REPLACE??</p>??\n',
                    image: {
                        check: '',
                        url: '',
                        alt: ''
                    }
                },
                after: {
                    text: '',
                    image: {
                        check: '',
                        url: '',
                        alt: ''
                    }
                }
            },
            link: {
                author: 'RAWHTML!!SAND??workAuthorName":"??",!!REPLACE??^??https://kakuyomu.jp/users/',
                next: 'VAL!//div[@id="contentMain-nextEpisode"]/a/@href',
                prev: 'VAL!//div[@id="contentMain-previousEpisode"]/a/@href',
                index: 'VAL!//title[contains(text(), "閉じる")]/../@href',
            }
        },
        index: {
            title: '//h1[@id="workTitle"]/a',
            author: '//span[@id="workAuthor-activityName"]/a',
            abstract: '//p[@id="introduction"]!!REPLACE??\n??!!REPLACE??<br>??\n!!CLEANTAG!!REPLACE??…続きを読む??\n',
            link: {
                author: 'VAL!//span[@id="workAuthor-activityName"]/a/@href'
            },
            item: {
                container: '//div[@id="table-of-contents"]/section/div/ol/*',
                chapter: {
                    check: 'contains(./@class, "widget-toc-chapter")',
                    title: './span',
                },
                episode: {
                    check: 'contains(./@class, "widget-toc-episode")',
                    title: './a/span',
                    link: 'VAL!./a/@href',
                    date: './a/time',
                    dateFormat: 'YYYY年MM月DD日'
                }
            }
        }
    },
    'www.alphapolis.co.jp': {
        metaData: {
            name: "アルファポリス",
            version: 1,
            metaVersion: 1,
            recommend: true,
            url: {
                index: "https://www\.alphapolis\.co\.jp/novel/[0-9]*/[0-9]*$",
                episode: "https://www\.alphapolis\.co\.jp/novel/[0-9]*/[0-9]*/episode/[0-9]*$"
            }
        },
        episode: {
            title: '//h1[contains(@class, "title")]/a!!REPLACE??\n|\t??',
            author: '//h2[contains(@class, "author")]/a',
            chapterTitle: '//div[contains(@class, "chapter-title")]!!REPLACE??\n|\t??',
            subTitle: '//h2[contains(@class, "episode-title")]!!REPLACE??\n|\t??',
            text: {
                prev: {
                    text: '',
                    image: {
                        check: '',
                        url: '',
                        alt: ''
                    }
                },
                main: {
                    text: '//div[contains(@id, "novelBoby")]!!REPLACE??\t|\n??!!REPLACE??<br>??\n',
                    image: {
                        check: '<img src=\"https:\/\/cdn-image.alphapolis.co.jp\/.+?\">',
                        url: 'REPLACE??^<img src="??!!REPLACE??">$??',
                        alt: ''
                    }
                },
                after: {
                    text: '',
                    image: {
                        check: '',
                        url: '',
                        alt: ''
                    }
                }
            },
            link: {
                author: 'VAL!//h2[contains(@class, "author")]/a/@href',
                next: 'VAL!//div[contains(@class, "episode-navigation")]/a[contains(@class, "next")]/@href',
                prev: 'VAL!//div[contains(@class, "episode-navigation")]/a[contains(@class, "prev")]/@href',
                index: 'VAL!//h1[contains(@class, "title")]/a/@href',
            }
        },
        index: {
            title: '//h2[contains(@class, "title")]!!REPLACE??\n|\t??',
            author: '//div[contains(@class, "author")]/span/a[1]',
            abstract: '//div[contains(@class, "abstract")]!!REPLACE??\t|\n??!!REPLACE??<br>??\n',
            link: {
                author: 'VAL!//div[contains(@class, "author")]/span/a[1]/@href',
            },
            item: {
                container: '//div[contains(@class, "body")]/div[contains(@class, "episodes")]/*',
                chapter: {
                    check: 'name() = "h3"',
                    title: '.!!REPLACE??\n|\t??',
                },
                episode: {
                    check: 'name() = "div"',
                    title: './a/span[contains(@class, "title")]!!REPLACE??\n|\t??!!CLEANTAG',
                    link: 'VAL!./a/@href',
                    date: './a/span[contains(@class, "open-date")]',
                    dateFormat: 'YYYY.MM.DD HH:mm'
                }
            }
        }
    },
    'novelup.plus': {
        metaData: {
            name: "ノベルアップ＋",
            version: 1,
            metaVersion: 1,
            recommend: true,
            url: {
                index: "https://novelup\.plus/story/[0-9]+$",
                episode: "https://novelup\.plus/story/[0-9]+$/[0-9]+$"
            }
        },
        episode: {
            title: '//div[contains(@class, "novel_title")]/p',
            author: '//div[contains(@class, "novel_author")]/p/a',
            chapterTitle: '',
            subTitle: '//div[contains(@class, "episode_title")]/h1',
            text: {
                prev: {
                    text: '',
                    image: {
                        check: '',
                        url: '',
                        alt: ''
                    }
                },
                main: {
                    text: '//div[contains(@class, "content")]/p',
                    image: {
                        check: '<img src=\"https:\/\/novelup.plus\/uploads\/.+?\">',
                        url: 'REPLACE??^<img src="??!!REPLACE??">$??',
                        alt: ''
                    }
                },
                after: {
                    text: '',
                    image: {
                        check: '',
                        url: '',
                        alt: ''
                    }
                }
            },
            link: {
                author: 'VAL!//div[contains(@class, "novel_author")]/p/a/@href',
                next: 'VAL!//div[contains(@class, "move_set")]/div/a/p[contains(text(), "次へ")]/../@href',
                prev: 'VAL!//div[contains(@class, "move_set")]/div/a/p[contains(text(), "前へ")]/../@href',
                index: 'VAL!//div[contains(@class, "move_set")]/div/a/p[contains(text(), "目次")]/../@href',
            }
        },
        index: {
            title: '//div[contains(@class, "novel_title")]/h1',
            author: '//div[contains(@class, "novel_author")]/p/a',
            abstract: '//div[contains(@class, "novel_synopsis")]/p',
            link: {
                author: 'VAL!//div[contains(@class, "novel_author")]/p/a/@href',
            },
            item: {
                container: '//div[contains(@class, "episode_list")]/ul/*',
                chapter: {
                    check: 'contains(./@class, "chapter")',
                    title: './cite',
                },
                episode: {
                    check: 'not(contains(./@class, "chapter"))',
                    title: './div[contains(@class, "episode_link")]/a!!REPLACE??.+?　??',
                    link: 'VAL!./div[contains(@class, "episode_link")]/a/@href',
                    date: './div[contains(@class, "update_date")]/p/span[1]/span',
                    dateFormat: 'YYYY/MM/DD HH:mm'
                }
            }
        }
    }
};
