"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EpisodeParser = exports.RowTypes = void 0;
const parser_1 = require("./parser");
exports.RowTypes = {
    Text: 'text',
    Image: 'image',
};
class EpisodeParser extends parser_1.Parser {
    constructor(url, html, selector) {
        super(html);
        this.url = url;
        this.selector = selector;
    }
    get() {
        return {
            url: this.url,
            title: {
                novel: this.getTitle(),
                chapter: this.getChapterTitle(),
                sub: this.getSubTitle(),
            },
            text: {
                prev: this.getTextPrev(),
                main: this.getTextMain(),
                after: this.getTextAfter(),
            },
            author: {
                name: this.getAuthor(),
                link: this.getAuthorLink(),
            },
            link: {
                next: this.getNextLink(),
                prev: this.getPrevLink(),
                index: this.getIndexLink(),
            },
        };
    }
    // 著者名
    getAuthor() {
        return this.getText(this.tempElem, this.selector.author);
    }
    // 著者ページリンク
    getAuthorLink() {
        return this.getURL(this.url, this.tempElem, this.selector.link.author);
    }
    // 作品タイトル
    getTitle() {
        return this.getText(this.tempElem, this.selector.title);
    }
    // 章タイトル
    getChapterTitle() {
        return this.getText(this.tempElem, this.selector.chapterTitle);
    }
    // 各話タイトル
    getSubTitle() {
        return this.getText(this.tempElem, this.selector.subTitle);
    }
    splitText(selector) {
        const text = this.getHtml(this.tempElem, selector.text) || "";
        const ret = [];
        for (const row of text.split(/\n/)) {
            const imgMatch = selector.image.check && row.match(selector.image.check);
            if (imgMatch) {
                ret.push({
                    type: exports.RowTypes.Image,
                    data: this.runFunc(imgMatch[0], selector.image.url.split('!!'))
                });
            }
            else {
                ret.push({
                    type: exports.RowTypes.Text,
                    data: row
                });
            }
        }
        return ret;
    }
    // 本文
    getTextMain() {
        return this.splitText(this.selector.text.main);
    }
    // まえがき
    getTextAfter() {
        return this.splitText(this.selector.text.after);
    }
    // あとがき
    getTextPrev() {
        return this.splitText(this.selector.text.prev);
    }
    // 次話リンク
    getNextLink() {
        return this.getURL(this.url, this.tempElem, this.selector.link.next);
    }
    // 前話リンク
    getPrevLink() {
        return this.getURL(this.url, this.tempElem, this.selector.link.prev);
    }
    // 目次リンク
    getIndexLink() {
        return this.getURL(this.url, this.tempElem, this.selector.link.index);
    }
}
exports.EpisodeParser = EpisodeParser;
