var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { KeyboardAvoidingView, Platform, StyleSheet, View, PlatformColor, } from "react-native";
import Modal from "./Modal";
import useTheme from "./useTheme";
import PropTypes from "prop-types";
const iOS = Platform.OS === "ios";
const DialogContainer = (props) => {
    const { blurComponentIOS, buttonSeparatorStyle = {}, children, contentStyle = {}, footerStyle = {}, headerStyle = {}, blurStyle = {}, visible = false, keyboardVerticalOffset = 40 } = props, nodeProps = __rest(props, ["blurComponentIOS", "buttonSeparatorStyle", "children", "contentStyle", "footerStyle", "headerStyle", "blurStyle", "visible", "keyboardVerticalOffset"]);
    const titleChildrens = [];
    const descriptionChildrens = [];
    const buttonChildrens = [];
    const otherChildrens = [];
    const { styles } = useTheme(buildStyles);
    React.Children.forEach(children, (child) => {
        if (!child) {
            return;
        }
        if (child.type.name === "DialogTitle" ||
            child.type.displayName === "DialogTitle") {
            titleChildrens.push(child);
        }
        else if (child.type.name === "DialogDescription" ||
            child.type.displayName === "DialogDescription") {
            descriptionChildrens.push(child);
        }
        else if (child.type.name === "DialogButton" ||
            child.type.displayName === "DialogButton") {
            if (Platform.OS === "ios" && buttonChildrens.length > 0) {
                buttonChildrens.push(React.createElement(View, { style: [styles.buttonSeparator, buttonSeparatorStyle] }));
            }
            buttonChildrens.push(child);
        }
        else {
            otherChildrens.push(child);
        }
    });
    return (React.createElement(Modal, Object.assign({ renderToHardwareTextureAndroid: true, transparent: true, visible: visible }, nodeProps),
        React.createElement(KeyboardAvoidingView, { behavior: iOS ? "padding" : undefined, keyboardVerticalOffset: iOS ? keyboardVerticalOffset : undefined, style: styles.centeredView },
            React.createElement(View, { style: [styles.content, contentStyle] },
                Platform.OS === "ios" && blurComponentIOS,
                Platform.OS === "ios" && !blurComponentIOS && (React.createElement(View, { style: [styles.blur, blurStyle] })),
                React.createElement(View, { style: [styles.header, headerStyle] },
                    titleChildrens,
                    descriptionChildrens),
                otherChildrens,
                Boolean(buttonChildrens.length) && (React.createElement(View, { style: [styles.footer, footerStyle] }, buttonChildrens.map((x, i) => React.cloneElement(x, {
                    key: `dialog-button-${i}`,
                }))))))));
};
DialogContainer.propTypes = {
    blurComponentIOS: PropTypes.node,
    buttonSeparatorStyle: PropTypes.object,
    contentStyle: PropTypes.object,
    footerStyle: PropTypes.object,
    headerStyle: PropTypes.object,
    blurStyle: PropTypes.object,
    visible: PropTypes.bool,
    onBackdropPress: PropTypes.func,
    keyboardVerticalOffset: PropTypes.number,
    // @ts-expect-error: PropType allows strings and the Typescript interface does not
    children: PropTypes.node.isRequired,
};
const buildStyles = () => StyleSheet.create({
    centeredView: {
        marginTop: 22,
    },
    blur: {
        position: "absolute",
        backgroundColor: PlatformColor("systemGray6"),
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    content: Platform.select({
        ios: {
            width: 270,
            //backgroundColor: PlatformColor("systemGray6"),
            flexDirection: "column",
            borderRadius: 13,
            overflow: "hidden",
        },
        android: {
            backgroundColor: PlatformColor("?attr/colorBackgroundFloating"),
            flexDirection: "column",
            borderRadius: 3,
            padding: 16,
            margin: 16,
            overflow: "hidden",
            elevation: 4,
            minWidth: 300,
        },
        web: {
            flexDirection: "column",
            borderRadius: 3,
            padding: 16,
            margin: 16,
            backgroundColor: "white",
            overflow: "hidden",
            elevation: 4,
            minWidth: 300,
        },
        default: {},
    }),
    header: Platform.select({
        ios: {
            margin: 18,
        },
        android: {
            margin: 12,
        },
        web: {
            margin: 12,
        },
        default: {},
    }),
    footer: Platform.select({
        ios: {
            flexDirection: "row",
            justifyContent: "space-between",
            borderTopColor: PlatformColor("separator"),
            borderTopWidth: StyleSheet.hairlineWidth,
            height: 46,
        },
        android: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 4,
        },
        web: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: 4,
        },
        default: {},
    }),
    buttonSeparator: {
        height: "100%",
        backgroundColor: PlatformColor("separator"),
        width: StyleSheet.hairlineWidth,
    },
});
export default DialogContainer;
