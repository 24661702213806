import DialogButton from "./Button";
import DialogContainer from "./Container";
import DialogDescription from "./Description";
import DialogInput from "./Input";
import DialogSwitch from "./Switch";
import DialogTitle from "./Title";
export default {
    Button: DialogButton,
    Container: DialogContainer,
    Description: DialogDescription,
    Input: DialogInput,
    Switch: DialogSwitch,
    Title: DialogTitle,
};
